import React, { useEffect } from 'react';
import Jumbotron from './Jumbotron';
import Navbar from './Navbar';

const Party = ({ navItems, jumbotronTitle, jumbotronSubtitle, firstPartyURL, firstPartyIsLink }) => {
    let didRunStartupScripts = false;

    useEffect(() => {
        if (window.runComponentDidMount && !didRunStartupScripts) {
            didRunStartupScripts = true;
            window.runComponentDidMount();
        }
    })

    return (
        <div>
            <Navbar
                navItems={navItems}
            />
            <Jumbotron
                jumbotronTitle={jumbotronTitle}
                jumbotronSubtitle={jumbotronSubtitle}
                firstPartyURL={firstPartyURL}
                firstPartyIsLink={firstPartyIsLink}
            />

            <div className="container">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 col-xs-12">
                        <p className="text-center" data-i18n="finn-party-summary0">As we are currently not living in Barcelona, but we have the majority of our social circle there, we’ve decided to throw a party for people to get to know our son!</p>
                        <p className="text-center">
                            <span data-i18n="finn-party-summary1">What’s the deal? </span>
                            <span className="font-weight-bold" data-i18n="finn-party-date">Saturday September 22nd</span>
                            <span data-i18n="finn-party-summary2"> at </span>
                            <span className="font-weight-bold" data-i18n="finn-party-time">15:00</span>
                            <span data-i18n="finn-party-summary3"> you are expected at </span>
                            <span className="font-weight-bold" data-i18n="finn-party-location">Plaza Lesseps 17</span>
                            <span data-i18n="finn-party-summary4"> for a BBQ. No need to bring anything, we take care of everything (or better, the caterers do). Please </span>
                            <span className="font-weight-bold" data-i18n="finn-party-rsvp">RSVP before August 22nd</span>
                            <span data-i18n="finn-party-summary5">, in case you need to change/cancel your RSVP contact us ahead of time!</span></p>
                    </div>
                    <div className="col-lg-2"></div>
                </div>

                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 col-xs-12">
                        <a className="btn btn-primary btn-block" style={{ marginTop: '10px', backgroundColor: '#C452B4' }} href="https://lullasfirstparty.rsvpify.com" role="button" target="_blank" rel="noreferrer">RSVP &raquo;</a>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>

        </div>
    );
};

export default Party;