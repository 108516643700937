import React, { useEffect } from 'react';
import LoginForm from './login_form'

const Login = (props) => {
  let didRunStartupScripts = false;

  useEffect(() => {
    if (window.runComponentDidMount && !didRunStartupScripts) {
      didRunStartupScripts = true;
      window.runComponentDidMount();
    }
  })

  return (
    <div>
      <div className="jumbotron pt-4" style={{ 'paddingTop': '0.5rem', 'paddingBottom': '1rem' }} id="login_jumbotron">
        <div className="container" align="center">
          <div className="row">
            <div className="col"><h3 className="i18nswap" data-i18n="finn-login-welcome">'Welcome!'</h3></div>
          </div>
          <div className="row">
            <div className="col"><h3 className="i18nswap" data-i18n="finn-login-language-prompt">'Choose your language'</h3></div>
          </div>
          <div className="row switch-locale" style={{ 'marginTop': '1rem', 'marginLeft': '0', 'marginRight': '0', 'textAlign': 'center', 'width': '100%' }}>
            <div className="col-xl col-lg-2"></div>
            <div className="col-xl-1 col-lg-2 col-3">
              <a href="#" data-locale="ca">
                <div className="i18ncontainer i18n-ca">
                  <img className="i18nactive" src="/images/flags/ca.svg" style={{ 'height': '50px' }} alt="Catalan language" />
                  <img className="i18nhidden" src="/images/flags/ca.svg" style={{ 'height': '50px', 'display': 'none' }} alt="Catalan language"  />
                </div>
              </a>
            </div>
            <div className="col-xl-1 col-lg-2 col-3">
              <a href="#" data-locale="nl">
                <div className="i18ncontainer i18n-nl">
                  <img className="i18nactive" src="/images/flags/nl.svg" style={{ 'height': '50px' }} alt="Dutch language"  />
                  <img className="i18nhidden" src="/images/flags/flanders.svg" style={{ 'height': '50px', 'display': 'none' }}  alt="Flemish language" />
                </div>
              </a>
            </div>
            <div className="col-xl-1 col-lg-2 col-3">
              <a href="#" data-locale="en">
                <div className="i18ncontainer i18n-en">
                  <img className="i18nactive" src="/images/flags/us.svg" style={{ 'height': '50px' }} alt="US English language"  />
                  <img className="i18nhidden" src="/images/flags/uk.svg" style={{ 'height': '50px', 'display': 'none' }} alt="UK English language"  />
                </div>
              </a>
            </div>
            <div className="col-xl-1 col-lg-2 col-3">
              <a href="#" data-locale="es">
                <div className="i18ncontainer i18n-es">
                  <img className="i18nactive" src="/images/flags/es.svg" style={{ 'height': '50px' }} alt="Spanish language"  />
                  <img className="i18nhidden" src="/images/flags/es.svg" style={{ 'height': '50px', 'display': 'none' }} alt="Spanish language"  />
                </div>
              </a>
            </div>
            <div className="col-xl col-lg-2"></div>
          </div>
        </div>
      </div>
      <LoginForm user={props.user} onUserChange={props.onUserChange} />
    </div>
  );
};

export default Login;