import React from 'react';
import { Link } from "react-router-dom";
import LullaSVG from '../images/lulla.svg'

function Jumbotron({jumbotronTitle, jumbotronSubtitle, firstPartyURL, firstPartyIsLink}) {
    let partyBanner = null;
    if (firstPartyURL && firstPartyIsLink) {
        partyBanner = <div id='party-cta'><Link to='/party'><img src={firstPartyURL} width='100%' height='auto' alt="Partaaaay" /></Link></div>;
    }
    else if (firstPartyURL && !firstPartyIsLink) {
        partyBanner = <div id='party-cta'><img src={firstPartyURL} width='100%' height='auto' alt="Partaaaay" /></div>;
    }

    return (
        <div className="jumbotron mt-5 mt-sm-3 pb-4" style={{ position: 'relative' }}>
            <div className="container-fluid text-center">
                <div className="row mt-3">
                    <div className="col-2 col-sm-3 col-lg-4"></div>
                    <div className="col-8 col-sm-6 col-lg-4"><img src={LullaSVG} width="100%" height="auto" alt="Finn's logo" /></div>
                    <div className="col-2 col-sm-3 col-lg-4"></div>
                </div>
            </div>
            <div className="container mt-3">
                <div data-i18n={jumbotronTitle}></div>
                <div data-i18n={jumbotronSubtitle}></div>
            </div>
            {partyBanner}
        </div>
    );
}

export default Jumbotron;