import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Jumbotron from './Jumbotron';
import Navbar from './Navbar';

const Main = ({ navItems, jumbotronTitle, jumbotronSubtitle, firstPartyURL, firstPartyIsLink }) => {
    let didRunStartupScripts = false;

    const partyCTAJSX = () => {
        return <div className="container">
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 col-xs-12">
                    <Link className="btn btn-primary btn-block" style={{ marginTop: '10px', backgroundColor: '#C452B4' }} to="/party" role="button" data-i18n="party-info">Party Info &raquo;</Link>
                </div>
                <div className="col-lg-2"></div>
            </div>
            <hr style={{ marginTop: '40px', marginBottom: '20px' }} />
        </div>
    }
    let partyCTA = null;
    if (firstPartyURL) {
        partyCTA = partyCTAJSX();
    }

    useEffect(() => {
        if (window.runComponentDidMount && !didRunStartupScripts) {
            didRunStartupScripts = true;
            window.runComponentDidMount();
        }
    })

    return (
        <div>
            <Navbar
                navItems={navItems}
            />
            <Jumbotron
                jumbotronTitle={jumbotronTitle}
                jumbotronSubtitle={jumbotronSubtitle}
                firstPartyURL={firstPartyURL}
                firstPartyIsLink={firstPartyIsLink}
            />

            {partyCTA}
            <div className="container">
                <div className="row" style={{rowGap: '0'}}>

                    <a name="bank-transfer"></a>
                    <div className="col-xl-4 col-lg-6 mb-5 mb-xl-0">
                        <h2 data-i18n="finn-main-bank-transfer">Bank Transfer</h2>
                        <p data-i18n="finn-main-bank-transfer-detail0">Mum &amp; dad have opened a bank account for me, or you can opt to use PayPal, although daddy isn’t a huge fan of that last company. TransferWise is a better choice.</p>
                        <p data-i18n="finn-main-bank-transfer-detail1">To avoid transfer costs, look at the other options. No cheques please.</p>

                        <h5 style={{ marginTop: '20px' }} data-i18n="finn-main-bank-transfer-header">Transfer:</h5>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-4" data-i18n="finn-main-bank-transfer-bank-def">BANK:</div><div className="col-8">Argenta</div>
                                <div className="col-4" data-i18n="finn-main-bank-transfer-iban-def">IBAN:</div><div className="col-8">BE38 9734 3346 7772</div>
                                <div className="col-4" data-i18n="finn-main-bank-transfer-swift-def">SWIFT/BIC:</div><div className="col-8">ARSPBE22</div>
                                <div className="col-4" data-i18n="finn-main-bank-transfer-name-def">NAME:</div><div className="col-8">Lieselot Schelstraete</div>
                            </div>
                        </div>

                        <h5 style={{ marginTop: '20px' }} data-i18n="finn-main-bank-paypal-header">PayPal:</h5>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">lieselotschelstraete@gmail.com</div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <a name="shopping"></a>
                    <div className="col-xl-4 col-lg-6 mb-5 mb-xl-0">
                        <h2 data-i18n="finn-main-gift-options">Gift Options</h2>
                        <p data-i18n="finn-main-extra-shopping"></p>
                    </div>

                    <hr />

                    <a name="amazon"></a>
                    <div className="col-xl-4 col-lg-6 mb-5 mb-xl-0">
                        <h2 data-i18n="finn-main-amazon-registry-title">Amazon Birth Registry</h2>
                        <p data-i18n="finn-main-amazon-registry-detail">Shop in your own language and currency! Have it delivered for free (all but one item) to our doorstep.</p>

                        <p><a className="btn btn-outline-secondary btn-block" style={{ marginTop: '10px' }} href="https://www.amazon.de/baby-reg/lulla-schelstraete-june-2022-hellerup/AJ35JX9LV9FS" role="button" target="_blank" data-i18n="finn-main-amazon-registry-cta">Go To Amazon &raquo;</a></p>
                    </div>

                    <hr />

                    <a name="babyscorner"></a>
                    <div className="col-xl-4 col-lg-6 mb-5 mb-xl-0">
                        <h2 data-i18n="finn-main-babys-corner-registry-title">Baby's Corner Birth Registry</h2>
                        <p data-i18n="finn-main-babys-corner-registry-detail">Shop in your own language and currency! Have it delivered for free (all but one item) to our doorstep.</p>
                        <p><strong data-i18n="login"></strong> Lullaschelstraete</p>
                        <p><strong data-i18n="password"></strong> 24062022</p>

                        <p><a className="btn btn-outline-secondary btn-block" style={{ marginTop: '10px' }} href="https://www.thebabyscorner.be/nl-be/profile/listlogin" role="button" target="_blank" data-i18n="finn-main-babys-corner-registry-cta">Go To Baby's Corner &raquo;</a></p>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Main;