import React from 'react';
import {
    Navigate,
    Route,
    Routes,
    BrowserRouter as Router
} from 'react-router-dom'

import Login from './components/login';
import Main from './components/main';
import Party from './components/Party';
import Lulla1stPartyNoCTA from './images/lulla-1st-party-no-cta.png'

const MyRoutes = ({ user, onUserChange }) => {
    const RequireAuth = ({ children }) => {
        if (user !== null) {
            return children;
        }

        return <Navigate to='/' replace />
    }

    const navItems = [
            { title: "Bank Transfer", destination: "#bank-transfer" },
            { title: "Gift Options", destination: "#shopping" },
            { title: "Amazon", destination: "#amazon" },
            { title: "Baby's Corner", destination: "#babyscorner" },
        ];
    const firstPartyURL = (user && user.username === 'babydrinker') ? Lulla1stPartyNoCTA
        : null;

    return (
        <Router>
            <Routes>
                <Route path='/' element={<Login user={user} onUserChange={onUserChange} />} />
                <Route
                    path='/main'
                    element={
                        <RequireAuth>
                            <Main navItems={navItems} jumbotronTitle='finn-main-welcome' jumbotronSubtitle='finn-main-thanks' firstPartyURL={firstPartyURL} firstPartyIsLink={true} />
                        </RequireAuth>
                    } />
                <Route
                    path='/party'
                    element={
                        <RequireAuth>
                            <Party navItems={[]} jumbotronTitle='finn-party-subtitle' firstPartyURL={firstPartyURL} firstPartyIsLink={false} />
                        </RequireAuth>
                    } />
                <Route path="*" element={<Navigate to='/' replace />} />
            </Routes>
        </Router>
    )
}

export default MyRoutes