import React from 'react';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';

const LoginForm = (props) => {
    const password = React.createRef();
    const birthday = React.createRef();
    let loginFormChosenElement = null;

    const keypress_login_form = (event) => {
        password.current.classList.remove('is-invalid');
        birthday.current.classList.remove('is-invalid');

        if (event.charCode === 13 && event.altKey) {
            submit_login_form(event, true);
            return false;
        }
        return true;
    };

    const handleSubmit = (event) => {
        submit_login_form(event, false);
    };

    const submit_login_form = (event, isAdmin) => {
        event.preventDefault();
        loginFormChosenElement = null;

        var password_val = password.current.value;
        var birthday_val = birthday.current.value;
        var username = null;
        if (birthday_val.length) {
            username = 'boringauthorizeduser';
            password_val = birthday_val;
            loginFormChosenElement = birthday;
        } else if (password_val.length) {
            username = isAdmin ? 'admin' : 'babydrinker';
            loginFormChosenElement = password;
        }

        if (!username || password_val.length === 0) {
            return;
        }

        password_val = password_val.toLowerCase();
        signIn(username, password_val);
    }

    const signIn = async (username, password) => {
        window.loginSubmissionInProgress = true;
        window.update_login_form_enabled();

        try {
            const newUser = await Auth.signIn(username, password);
            login_form_success(newUser);
        } catch (error) {
            console.log('error signing in', error);
            login_form_error();
        }

        window.loginSubmissionInProgress = false;
        window.update_login_form_enabled();
    }

    const navigate = useNavigate();

    const login_form_success = (newUser) => {
        props.onUserChange(newUser);

        var destination = '/main';
        var locale = window.get_locale();
        if (locale && locale.length) {
            destination += '?locale=' + locale;
        }

        navigate(destination);
    }

    const login_form_error = (jqXHR, textStatus, errorThrown) => {
        if (loginFormChosenElement) {
            loginFormChosenElement.current.classList.add('is-invalid')
            loginFormChosenElement = null;
        }
    }

    return (
        <form id="login-form" method="post" acceptCharset="UTF-8" encType="multipart/form-data" onKeyPress={keypress_login_form} onSubmit={handleSubmit}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 col-12">
                        <div className="form-group">
                            <label className="i18nswap" htmlFor="password" id="passwordDescription" data-i18n="finn-login-party-password-prompt">'Got the website code? Enter it here!'</label>
                            <div className="input-group input-group-sm">
                                <input type="password" className="form-control" id="password" aria-describedby="passwordDescription" placeholder="Enter party password" name="password" ref={password} />
                                <div className="invalid-feedback" data-i18n="finn-bad-password">'Try again please!'</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 col-12">
                        <div className="form-group">
                            <label className="i18nswap" htmlFor="birthday" id="birthdayDescription" data-i18n="finn-login-login-prompt">'If you forgot it, enter Lulla's date of birth instead!'</label>
                            <div className="input-group input-group-sm date" data-provide="datepicker" id="datepickerGroup">
                                <input type="text" className="form-control" id="birthday" aria-describedby="birthdayDescription" placeholder="18/07/1972" name="birthday" ref={birthday} />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" aria-haspopup="true" aria-expanded="false">📅</button>
                                </div>
                                <div className="invalid-feedback" data-i18n="finn-bad-password">'Try again please!'</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 col-12">
                        <div className="form-group">
                            <button name="login_button" type="submit" className="btn btn-primary btn-block i18nswap" data-i18n="finn-login-go" style={{backgroundColor: '#C452B4'}} disabled={false}>'Go!'</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default LoginForm;