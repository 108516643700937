import React from 'react';
import { Link } from "react-router-dom";
import LullaL from '../images/lulla-l.svg'

function Navbar({navItems}) {
    const navJSX = navItems.map( item => <li className="nav-item" key={item.title}><a className="nav-link" href={item.destination}>{item.title}</a></li>);

    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
            {/* <Link className="navbar-brand mr-0 mr-md-2" to="/main" aria-label="Lulla">
                <img src={LullaL} height="36px" alt="Lulla's Logo" />
            </Link> */}
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                <ul className="navbar-nav mr-auto">
                    {navJSX}
                </ul>
                <ul className="navbar-nav mr-0">
                    <li className="nav-item dropdown">
                        <div className="nav-link dropdown-toggle" id="i18n-current" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="i18ncontainer i18n-en">
                                <img className="i18nactive" src="/images/flags/us.svg" style={{height: '24px'}} alt="US English language" />
                                <img className="i18nhidden" src="/images/flags/uk.svg" style={{height: '24px', display: 'none'}} alt="UK English language" />
                            </div>
                        </div>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01">
                            <div className="dropdown-item i18ncontainer mb-2 i18n-ca" data-locale="ca">
                                <img className="i18nactive" src="/images/flags/ca.svg" style={{height: '24px'}} alt="Catalan language" />
                                <img className="i18nhidden" src="/images/flags/ca.svg" style={{height: '24px', display: 'none'}} alt="Catalan language" />
                                <span className="ml-2">Català</span>
                            </div>
                            <div className="dropdown-item i18ncontainer mb-2 i18n-nl" data-locale="nl">
                                <img className="i18nactive" src="/images/flags/nl.svg" style={{height: '24px'}} alt="Dutch language" />
                                <img className="i18nhidden" src="/images/flags/flanders.svg" style={{height: '24px', display: 'none'}} alt="Flemish language" />
                                <span className="ml-2">Vlaams/Nederlands</span>
                            </div>
                            <div className="dropdown-item i18ncontainer mb-2 i18n-en" data-locale="en">
                                <img className="i18nactive" src="/images/flags/us.svg" style={{height: '24px'}} alt=
                                "US English language" />
                                <img className="i18nhidden" src="/images/flags/uk.svg" style={{height: '24px', display: 'none'}} alt="UK English language" />
                                <span className="ml-2">American/British</span>
                            </div>
                            <div className="dropdown-item i18ncontainer mb-2 i18n-es" data-locale="es">
                                <img className="i18nactive" src="/images/flags/es.svg" style={{height: '24px'}} alt="Spanish language" />
                                <img className="i18nhidden" src="/images/flags/es.svg" style={{height: '24px', display: 'none'}} alt="Spanish language" />
                                <span className="ml-2">Español</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;