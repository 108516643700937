import React, { useEffect } from 'react';
import MyRouter from './MyRouter';

// import { withAuthenticator } from '@aws-amplify/ui-react';

// import UnderConstruction from 'react-under-construction';
// import 'react-under-construction/build/css/index.css';
// import LullaLogo from './images/lulla_logo.png'

const App = () => {
  const [user, setUser] = React.useState(null);
  
  useEffect(() => {
    document.title = 'Welcome to Lulla\'s Page!';
  })

  return (
    // <><UnderConstruction
    //   background={{
    //     // image: 'https://static.pexels.com/photos/259698/pexels-photo-259698.jpeg',
    //     textColor: '#000',
    //     overlay: {
    //       color: '#fff',
    //       opacity: '1'
    //     }
    //   }}
    //   logo={{
    //     src: LullaLogo,
    //     alt: 'alt text',
    //     style: {'width': '80%'}
    //   }}
    //   title={{
    //     text: 'Lulla Schelstraete'
    //   }}
    //   description={{
    //     text: 'Lulla\'s website is under construction, just like she\'s been until very recently! We\'ll be here soon, check back tomorrow!',
    //     style: {
    //       maxWidth: '90%',
    //     }
    //   }}
    //  /></>

    <div>
      <MyRouter user={user} onUserChange={setUser} />
    </div>
  );
}

export default App;
// export default withAuthenticator(App);
